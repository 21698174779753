.banner2 {
  margin-bottom: 80px;
}
.banner2 > img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.mobile_banner {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@media only screen and (max-width: 480px) {
  .banner2 {
    margin-top: 20px;
  }
}

.product_header {
  margin-top: 50px;
  margin-bottom: 30px;
}

.product_container {
  margin-top: 10px;
  background-color: rgb(229 201 30);
  width: 100vw;
}

.card-container {
  height: 350px;
  width: 240px;
  cursor: pointer;

  transition: box-shadow 0.3s;
  border-radius: 10px;
  border: 1px solid #ccc;
  background: #fff;
}
.card-title {
  font: 20px bold Arial, sans-serif;
}

.card-container:hover {
  box-shadow: 0 0 15px green;
}

.card-img {
  height: 180px;
  width: 180px;
}

/* div > h3 {
  color: green;
  margin: 10px;
  padding: 50px;
  font-size: xx-large;
  font-weight: 700;
  font-family: Georgia, serif;
  background-color: rgb(163, 247, 177);
} */

.product_button {
  margin-top: 20px;
  margin-bottom: 40px;
  /* margin-left: 280px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col {
  padding-top: 30px;
}

.outer_div {
  margin-top: 20px;
  margin-bottom: 20px;
}

.product_heading {
  border-radius: 40%;
  display: table;
  margin-right: auto;
  margin-left: auto;

  margin-top: 80px;
  width: 40%;
  text-align: center;
  font-weight: 900;
  font-style: italic;
  text-decoration: underline;
}

@media only screen and (min-width: 901px) {
  .product_header {
    margin-top: 30px;
  }
  div > h3 {
    font-size: x-large;
  }
}

@media only screen and (max-width: 480px) {
  .card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 290px;
    width: 220px;
  }

  .card-title {
    font: 16px;
  }
 
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 801px) {
  .product_header {
    margin-top: 20px;
  }
  div > h3 {
    font-size: x-large;
  }
}

@media only screen and (max-width: 605px) {
  .product_header {
    margin-top: 40px;
  }
  /* div > h3 {
    font-size: x-large;
  } */
}


