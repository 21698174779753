.about_header {
  margin-top: 60px;
  padding-top: 20px;
}

.about {
  
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
}

.banner_image {
  float: left;
  height: 360px;
  width: 500px;
  border-radius: 4%;
  margin-right: 25px;
}

div > h1 {
  padding-top: 10px;
  text-align: center;
  color: black;
  text-decoration: underline;
}

#about_para {
  font-size: x-large;
  font-weight: 400;
  text-align: justify;
  font-size: 25px;
}

#links {
  color: green;
}

#links:hover {
  color: rgb(41, 61, 214);
}

#player-text {
  font-size: xx-large;
  color: green;
  font-weight: 600;
  padding-top: 50px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.earthnow {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  text-align: center;
}
#earthnow_heading {
  font-size: xx-large;
  color: green;
  font-weight: 600;
  padding-top: 50px;
  padding-bottom: 20px;
}

.earthnow_para {
  font-weight: 400;
  text-align: justify;
  font-size: 25px;
  margin-bottom: 50px;
}

@media only screen and (min-width: 1680px) {
  #about_para {
    font-size: x-large;
  }
}

@media only screen and (max-width: 801px) {
  .banner_image {
    display: block;
    border-radius: 1%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
    width: 100%;
  }

  #about_para {
    font-size: larger;
  }
  .about_video {
    width: 100%;
    height: 50vh;
  }

  #player-text {
    font-size: x-large;
  }
 

  #earthnow_heading {
    font-size: x-large;
  }
  .earthnow_para {
    font-size: larger;
  }
}

@media only screen and (max-width: 480px) {
  .banner_image {
    display: block;
    border-radius: 1%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    height: 300px;
    width: 100%;
  }

  #about_para {
    font-size: large;
  }
  .about_header {
    margin-top: 40px;
    padding-top: 20px;
  }

  #earthnow_heading {
    font-size: x-large;
    color: green;
    font-weight: 600;
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .earthnow_para {
    font-weight: 400;
    text-align: justify;
    font-size: large;
    margin-bottom: 50px;
  }

  #player-text {
    font-size: x-large;
    padding-left: 10px;
    padding-right: 10px;
  }
}
