.carousel-container {
  height: 400px;
  background-color: rgb(240 212 43);
  /* background-color: rgb(241, 220, 89); */
  display: flex;
  align-items: center;
  justify-items: space-evenly;
  cursor: pointer;

 
    
}


.categories_header {
  border-radius: 40%;
  display: table;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;

  margin-top: 80px;
  width: 40%;
  text-align: center;
  font-weight: 900;
  font-style: italic;
  text-decoration: underline;
}
.carousel {
  margin-left: 1px;
  padding-left: 1px;
  width: 100%;
  text-align: center;
}
.carousel_container {
  border: 2px solid darkgray;
  margin-top: 20px;
  height: 300px;
  width: 240px;

  transition: box-shadow .3s;
  border-radius:10px;
  /* border: 1px solid #ccc; */
  background: #fff;
  float: left;
}

.carousel_container:hover{
  box-shadow: 0 0 11px green; 
}

.carousel_image {
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_title {
  height: 20px;
}

.categories_button{
  margin-top: 120px;
}



@media only screen and (max-width: 480px){
  .carousel_container{
    height: 290px;
    width:192px;
  }
  .card-image{
    height: 220px;
  }
  div.card-title.h5{
    font-size: medium;
  }
  div.card_title.card-title.h5{
    font-size: larger;
  }
  .cardbody{
    padding-top: 20px;
  }
}