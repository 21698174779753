.mobile_banner{
  width: 100%;
  height: 43vh;
  display: flex;
  align-items: center;
  justify-content: center;

}
.mobile_banner > image{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}



