.nav-background {
  background: white;
}
.navbar {
  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
}

.background-change:hover {
  border-bottom: 2px solid orangered;
  color: orangered;
}

#dropdown-basic:hover {
  color: orangered;
}


.nav-link {
  color: orangered;
  font-weight: bold;
  font-size: larger;
  margin-inline: 2rem;
}



.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-left: 40px;
}


.dropdown_menu {
  font-size: large;
  font-weight: 600;
  padding-top: 10px;
  text-align: center;
  width: 50px;
}
.dropdown_menu:hover {
  font-size: larger;
  font-weight: 600;
  color: darkgreen;
  text-decoration: underline solid green;
  background-color: transparent;
   
}
.brand-text{
  color: orangered;
  font-weight: bold;
  font-size: xx-large;
  padding-left: "8px";
}
#hero{
  height: 80px;
  width: 80px;
}

@media only screen and (max-width: 480px){
  .brand-text{
    font-size: smaller;
  }
  #hero{
    height: 50px;
    width: 50px;
    margin-left: -24px;
  }

  #dropdown-basic{
    font-size: small;
  }
  .background-change{
    font-size: small;
  }
  .dropdown_menu{
    font-size: small;
  }

  .dropdown_menu:hover {
    font-size: large;
    font-weight: 400;
    color: darkgreen;
    text-decoration: underline solid green;
    background-color: transparent;
     
  }
}
