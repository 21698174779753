
.links_footer{
  font-size: 16px;
  text-decoration: none;
  }

  .links_footer:hover{
    text-decoration: underline;
  }
  .email_link{
    color:black;
    text-decoration: none;
  }
  .email_link:hover{
    text-decoration: underline;
  }
  .footer_social_links{
    display: flex; 

    align-items: center; 
  
    justify-content: center; 
  
    text-align: center; 
  
  }  
  

  .footer{
    width:100vw;
    
  }
  @media only screen and (max-width: 600px) {
    .links_footer{
      display: flex; 
      align-items: center;
      justify-content: center; 
      text-align: center; 
      margin-left: auto;
      margin-right: auto;
    }

   
  }